import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`5 ways to embrace the Mediterranean lifestyle in your back garden`}</h1>
    <span className="text-metadata">Posted on 1st March 2019</span>
    <Badge mdxType="Badge" />
    <img src="/img/outdoor-living/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/download.jpg" alt="A long outdoor dining table set with a lunch spread for six places" />
    <p><strong parentName="p">{`With February now behind us, Spring is in the air and temperatures should be on the up. Now is the ideal time to think ahead about home improvements, both indoors and out.`}</strong></p>
    <p>{`Having lived in the south west of France for several years before moving back to the UK, I still think back fondly on hours spent outside in the garden, enjoying the company of friends and family, eating barbecued food and sipping on rosé or a good tannic red. It was this happy and healthy outdoors lifestyle that led me to start up Perfect Pergolas. I mean, why should we Brits have to settle for a sedentary indoors lifestyle when our neighbours in mainland Europe have been enjoying outdoors living for years?`}</p>
    <p>{`Half the battle is fighting the assumption that we Brits can’t make the most of a healthy outdoor lifestyle because our weather won’t allow us. I say, it’s all about having a positive attitude. Only extreme bad weather - high winds and pounding rain - should prevent us from spending every minute possible outside in the garden. With a pergola, you’re one step closer to breaking the indoors habit and embracing the Mediterranean outdoors lifestyle fully. I hope our quick tips help inspire you to go for it. You’ll never look back!`}</p>
    <img src="/img/outdoor-living/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/download_(5).jpg" alt="An outdoor table and chair set, set with a light lunch, underneath a leafy pergola" />
    <h2>{`Five ways to embrace the Mediterranean lifestyle in your back garden:`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Install a pergola or outdoor structure:`}</strong>{`
Our lean-to pergolas are so much more than the sum of their parts. They bridge the gap between indoors and outdoors, without the need for planning permission or stressful building works. They allow you to bring the outdoors in. You simply install them yourself using our clear and straightforward instructions. Then comes the really fun bit…`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Plant up your pergola with climbers and vines`}</strong>{`
This helps to create a beautifully shaded canopy for when the sun’s strong, and brings you much closer to your plants than those in your borders and pots. With an established climber growing up and around your pergola, you are literally sounded by greenery, flowers, fruit and fragrance. If you grow a grapevine up your pergola, you could even eat grapes straight from the vine. Forget food miles, this is food inches!`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Add some outdoors furniture`}</strong>{`
While open-structure pergolas will never be fully waterproof, you don’t need to opt for rugged, mismatched outdoor furniture. Outdoors wicker, metal or properly treated wood is ideal - just avoid soft furnishings. Out of season, simply protect your table and chairs with suitable weatherproof covers or store indoors.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Choose a barbecue or firepit`}</strong>{`
Food eaten around burning logs or coals always tastes so much better, and with our BBQ 5-in-1 Cook Out you can enjoy eating al fresco all year round. It looks the business with its black steel tripod and black cauldron and frying plate. The firepit can be used for cooking, or enjoy it by itself for the sheer hell of it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Wait for the sun to come out`}</strong>{`
This one’s in the lap of the gods, but with the British climate warming in line with global trends, it’s not unthinkable that you’ll be thankful for the shade provided by your leafy pergola come high summer. And when the sun does come out, just make sure your friends are on standby to come round and enjoy a cold beer or cloudy lemonade under the shaded canopy of your new pergola.`}</p>
      </li>
    </ol>
    <p>{`I’m a true believer in how spending more time outdoors can enhance family life. If you have any questions at all about how our pergolas can help you embrace this healthy al fresco lifestyle, please contact me. I live and breathe Perfect Pergolas and am always happy to help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      